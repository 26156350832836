.modal-container {
    margin: 0 auto;
    position: relative;
    width: 160px;
}

.modal-container .modal-backdrop {
    height: 0;
    width: 0;
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.2s ease-in;
}

.modal-container #modal-toggle {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    opacity: 0;
    cursor: pointer;
}

.modal-container #modal-toggle:hover ~ button { background: #1E824C; }

.modal-container #modal-toggle:checked {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
    opacity: 0;
}

.modal-container #modal-toggle:checked ~ .modal-backdrop {
    background-color: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
    pointer-events: none;
    opacity: 1;
}

.modal-container #modal-toggle:checked ~ .modal-backdrop .modal-content {
    background-color: #fff;
    max-width: 400px;
    width: 100%;
    height: 280px;
    padding: 10px 30px;
    position: absolute;
    left: calc(50% - 200px);
    top: 12%;
    border-radius: 4px;
    z-index: 999;
    pointer-events: auto;
    cursor: auto;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.6);
}
@media (max-width: 400px) {

    .modal-container #modal-toggle:checked ~ .modal-backdrop .modal-content { left: 0; }
}

.modal-container #modal-toggle:checked ~ .modal-backdrop .modal-content .modal-close {
    color: #666;
    position: absolute;
    right: 2px;
    top: 0;
    padding-top: 7px;
    background: #fff;
    font-size: 16px;
    width: 25px;
    height: 28px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
}

.modal-container #modal-toggle:checked ~ .modal-backdrop .modal-content .modal-close.button {
    top: initial;
    bottom: 20px;
    right: 20px;
    background: #f1a534;
    color: #fff;
    width: 50px;
    border-radius: 2px;
    font-size: 14px;
    font-weight: normal;
}

.modal-container #modal-toggle:checked ~ .modal-backdrop .modal-content .modal-close.button:hover {
    color: #fff;
    background: #cb8d33;
}

.modal-container #modal-toggle:checked ~ .modal-backdrop .modal-content .modal-close:hover { color: #333; }

h2 {
    font-size: 1.5em;
}
